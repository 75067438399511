.App {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;

  img {
    max-width: 600px;
  }
}

.phone-picker {
  display: flex;
  flex-direction: row;

  button {
    flex-grow: 1;
    margin-right: 50px;
    padding: 50px 0;
    font-size: 3rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-container {
  height: 430px;
  .contact-card {
    background: #eee;
    max-width: 500px;
    max-height: 400px;
    border: 1px solid #777;
    border-radius: 4px;
    height: 400px;
    width: 400px;
    box-sizing: border-box;
    margin: 0 auto;
    aside {
      padding: 10px;
      overflow: hidden;
      overflow-y: auto;
    }

    &:first-child {
      top:40px;
      left:90px;
      z-index: 4;
    }

    &:nth-child(3) {
      top: 20px;
      left: 110px;
      z-index: 2;
    }

    p {
      text-align: left;
    }
  }
}

.action-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 2%;
  button {
    flex-basis: 49%;
    padding: 15px 0;
    border-radius: 0;
    box-sizing: border-box;
    border:2px solid #777;
    background: #eee;

    &.reject {
      border:2px solid hsl(0, 50%, 35%);
      background: hsl(0, 70%, 75%);
    }

    &.keep {
      border-color: hsl(120, 50%, 25%);
      background: hsl(120, 50%, 75%);
    }
  }
}

table.card-stats {
  margin: 10px auto 0;
}

@media (max-width: 600px) {
  .App img {
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .card-container .contact-card {
    max-width: 100%;
    aside {
      overflow: auto;
    }
  }
}

#reset-button {
  position: absolute;
  top: 0;
  left: 0;
}
